import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

/* global window */
const Zip = () => {
  const data = useStaticQuery(graphql`
    query {
      zip {
        environment
        publicKey
        seo {
          canonical
          description
          heading
          og {
            description
            image
            title
            type
            url
          }
          title
        }
      }
    }
  `);

  const { seo, environment, publicKey } = data.zip;

  const addScript = (url) => {
    const script = window.document.createElement('script');
    script.src = url;
    script.async = true;
    window.document.body.appendChild(script);
  };

  useEffect(() => {
    addScript('https://static.zipmoney.com.au/lib/js/zm-widget-js/dist/zip-widget.min.js');
  }, []);

  return (
    <Layout wrapperClass="" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData=""
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <h1 className="hidden">Repair now, pay later</h1>
      <div data-zm-merchant={publicKey} data-env={environment}></div>
      <div id="zip-LP" data-zm-asset="landingpage" data-zm-widget="inline"></div>
    </Layout >
  );
};

export default Zip;